import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import './index.css'
import bg from './bg1.jpg'
import blue from './whaLe.png'

import { motion } from 'framer-motion'
import DarkModeToggle from "react-dark-mode-toggle";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_DARKTHEME } from "../../redux/actions";
import theme from "styled-theming";
import { BsBoxArrowUp } from 'react-icons/bs';
import { Link, animateScroll as scroll } from "react-scroll";
import twi from './twitter.png'
import dis from './discord.png'
import Page2 from '../Page2/Page2'
import Page4 from '../Page4/Page4'
import Page3 from '../Page3/Page3';
import Page5 from '../Page5/Page5';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const buttonColor = theme("theme", {
  light: "linear-gradient(to right, #000000, #434343)",
  dark: "linear-gradient(to right, #ff4e50, #f9d423)",
});

const bColor = theme("theme", {
  light: "#E5E5E5",
  dark: "#000000",
});

const iconColor = theme("theme", {
  light: "linear-gradient(to right, #0f0c29, #302b63, #24243e)",
  dark: "linear-gradient(to right, #fc00ff, #00dbde)",
});

const heroImg = theme("theme", {
  light: blue,
  dark: blue,
});

const HomePage = styled.section`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
height: 100vh;
width: 100%;
// background-image: url(${bg});
// background-size: 100% 100%;
// background-repeat: no-repeat;
 background-color: #000;

@media only screen and (max-width: 768px) {
    min-height: 50rem;
    width: 100%;
    padding: 0rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
}`;

const HeroImg = styled(motion.div)`
    width: 38rem;
    height: 30rem;
    background-size: 100% 100%;
background-repeat: no-repeat;
background-image: url(${heroImg});
    @media only screen and (max-width: 1400px) {
        width: 30rem;
        height: 22rem;
      }
    @media only screen and (max-width: 768px) {
        width: 15rem;
        height: 12rem;
      }
`;

const T = styled.p`
    color: ;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    color:#fff;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const Mobile = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        display:block;
        width: 100%;
        height: auto;
      }
`;

const Center = styled.div`
        width: auto;
        height: auto;
        display: grid;
        place-items: center;
`;

// const Button = styled.button`
//      background: ${buttonColor} ;
//      color: ${bColor};
//      border: none;
//      font-size: 5rem;
//      font-weight: bold;
//      cursor: pointer;
//      padding: 0.3rem 3rem;
//      border-radius: 3rem;
//      margin-top: 2rem;
//      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
//         -webkit-transform: perspective(1px) translateZ(0);
//         transform: perspective(1px) translateZ(0);
//         box-shadow: 0 0 1px rgba(0, 0, 0, 0);
//         -webkit-transition-duration: 0.3s;
//         transition-duration: 0.3s;
//         -webkit-transition-property: transform;
//         transition-property: transform;

//     &:hover, &:focus,&:active {
//         -webkit-transform: scale(1.1) rotate(4deg);
//         transform: scale(1.1) rotate(4deg);
//       }
//       @media only screen and (max-width: 1400px) {
//         font-size: 4rem;
//         padding: 0.3rem 2rem;
//       }

//      @media only screen and (max-width: 768px) {
//         font-size: 2rem;
//         padding: 0.3rem 1rem;
//       }
// `;

const ModeButton = styled(DarkModeToggle)`
      position: fixed;
      bottom: 10%;
      left: 5rem;
      @media only screen and (max-width: 768px) {
        top : 5rem;
        left: 2rem;
      }
`
const ShowButton = styled(Link)`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background-color:#ffca38; // ${iconColor};
    color: #FFF ;
    font-weight: bold;
    cursor: pointer;
    position: fixed;
    height: 4rem;
    width: 3rem;
    border-radius: 0.5rem;
    transition:  0.5s linear;
    right: 3rem ;
    bottom: 10rem;
    display: grid;
    place-items: center;
    @media only screen and (max-width: 768px) {
      right: 2rem ;
    bottom: 5rem;
    }
    
`

const IconContainer = styled.div`
  display: flex;
  margin: 2rem 0 0 0;
`

const Icon = styled(motion.div)`
  width: 4.5rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 1rem;
  padding: 0.5rem;
  @media only screen and (max-width: 768px) {
    width: 3rem;
  height: 3rem;
  }
`
// background-image: url(${right});



export const H = styled.h1`
color: #ffca38;
font-size: 2.5rem;
font-weight: bold;
margin-bottom: 2rem;
text-align: center;
@media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const Image = styled.div`
      width: 20rem;
      height: 2rem;
      margin: 0 0 1rem 50%;
  
      @media only screen and (max-width: 1700px) {
        margin: 0 0 1rem 40%;
      }

      @media only screen and (max-width: 1400px) {
        margin: 0 0 1rem 35%;
      }

      @media only screen and (max-width: 1100px) {
        margin: 0 0 1rem 30%;
    }
    @media only screen and (max-width: 998px) {
        margin: 0 0 1rem 50%;
        width: 16rem;
    }
    @media only screen and (max-width: 768px) {
        margin: 0 0 1rem 50%;
        width: 12rem;
    }
    @media only screen and (max-width: 600px) {
        margin: 0 0 1rem 50%;
        width: 8rem;
    }
`
const Right = styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align:center;
        height: 42rem;
       
        background-size: 100% 100%;
        background-repeat: no-repeat;

        padding: 3.5rem 4rem 0 6rem;

        @media only screen and (max-width: 1700px) {
            height: 43rem;
          }

          @media only screen and (max-width: 1500px) {
            height: 45rem;
          
          }
          @media only screen and (max-width: 1400px) {
            padding: 3.5rem 2rem 0 3rem;
          }

          @media only screen and (max-width: 1100px) {
            width: 100%;
            margin-top: 5rem;
        }
        @media only screen and (max-width: 998px) {
            width: 100%;
            height: 38rem;
        }

      @media only screen and (max-width: 768px) {
        width: 100%;
        min-height: 25rem  ; 
        padding: 15rem 1rem 7rem 1.5rem;
      }
`;

export const Button = styled(motion.button)`
  background-color:#ffca38;
  border: none;
  padding: 0 2rem;
  width:100px;
  color: #000;
  height: 3.5rem;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
`

export const Promotion = styled(motion.button)`
  background-color:#ffca38;
  width:50%;
  padding:5px;
  font-size:2rem;
  font-weight:500;
  @media only screen and (max-width: 768px) {
    width: 100%;
    
  }
`
const Sadclown = () => {

  // const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
  // const dispatch = useDispatch();

  const [showButton, setShowButton] = useState(false)

  const showButtonTop = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 600) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  useEffect(() => {
    showButtonTop()
    // adding the event when scroll change background
    window.addEventListener("scroll", showButtonTop)
  })


  return (
    <div>
      <HomePage id="home">

        <div className='video'>
          <Right>
            <H className='second_font'>SAD Clown Collective</H>
            <T className='first_font'>Clown popularity has dwindled for the last century, as more and more people have come to see them as creepy or weird. This has caused a wave of sad clowns, who are unwanted by those they wish to entertain.</T>
            <T className='first_font'>
              Desperate, they have decided to band together to become the Sad Clown Collective; this Circus is coming to the blockchain and they are gathering up NFT's... and in numbers. Will the Collective's collection put a smile on your face?
            </T>
            <T className='first_font'>
              -----------------------------------------------
            </T>
            <T className='first_font'>
              Inspired by Dan's Great-Uncle Ken Moroney's 'Clowns', Sad Clown Collective will be a collection of 8,888 hand drawn NFT's. This project will be an investors/collectors club, buying and selling NFT's and splitting the profits made between its members and various charities. There will also be a staking mechanism and token to be introduced. We will be using this collection to raise awareness for mental health and giving part of the minting profits to a charity here in the UK and staging real world meet ups to spread the awareness.
            </T>
            {/* Meta Four Studios are a digital art creative focused on making the best and most ingenious NFT Projects around. </T> */}
            {/* <T>Shortages of food and resources have unleashed war between cities. Pledging allegiance to the corrupt forces is the only way to obtain the key to survival: the digital avatars of destruction.</T>
          <T>Get your Digital Avatar of Destruction and defeat your enemies to survive in a world with no place for the weak. Experience the most exciting vehicle combat game on the blockchain and play to earn amazing Crypto rewards.</T> */}
            {/* <Image>
            <img src={element} alt="" style={{ width: "100%", height: "100%" }} />
          </Image> */}
            {/* <Button
                            type="button"
                            whileHover={{ scale: 1.1, boxShadow: "0 0 25px #ffca38" }}
                            whileTap={{ scale: 0.7 }}
                        >Learn More</Button> */}
          </Right>

          {/* <img src='https://irp.cdn-website.com/a08a0621/dms3rep/multi/Clown+Gif.gif' style={{width:"100%"}} /> */}
        </div>
        {/* <video autoPlay loop muted className="video">
            <source src="https://vid.cdn-website.com/a08a0621/videos/1s3btOihQIWdEtxy1TnB_Pexels+Videos+2711131-v.mp4" autoPlay loop type="video/mp4"/>
          </video> */}

        {/* <Center>
               <HeroImg
                initial={{ x: 0, y:0}}
                animate={{ x: 0, y: -25 }}
                transition={{ duration: 1, repeat: Infinity ,repeatType: "reverse",}}
               >
                   <img src= {heroImg} alt="" style={{width:"100%",height:"100%"}} />
               </HeroImg>
               <Button 
                type="button"
               >
                   CONNECT
               </Button>
               <IconContainer>
                 <Icon
                  whileHover={{ scale: 1.1,boxShadow:"0 0 25px #000"}}
                  whileTap={{ scale: 0.7 }}
                 >
                   <img src={twi} alt="" style={{width:"100%",height:"100%"}} />
                 </Icon>
                 <Icon style={{marginLeft:"1rem"}}
                  whileHover={{ scale: 1.1,boxShadow:"0 0 25px 	#000"}}
                  whileTap={{ scale: 0.7 }}
                 >
                  <img src={dis} alt="" style={{width:"100%",height:"100%"}} />
                 </Icon>
               </IconContainer>
            </Center> */}

        {/* <ModeButton
                onChange={() => dispatch({ type: TOGGLE_DARKTHEME })}
                checked={darkThemeEnabled}
                size={80}
            /> */}

        {showButton ?

          <ShowButton
            type="button"
            to="home" spy={true} smooth={true}
          >
            <BsBoxArrowUp size="2rem" color='' />
          </ShowButton> :

          null
        }



      </HomePage>
    
      <div style={{height:"400px"}}>
        <Carousel style={{height:"400px"}}>
          <div style={{ height:"300px", background:"#a4ddfe"}}>
            <img src="/1.png" style={{width:"100%", objectFit:"contain", height:"100%"}} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div style={{ height:"300px", background:"linear-gradient(to right top ,#41a1a0 ,#9ceac5)"}}>
            <img src="/2.png" style={{width:"100%", objectFit:"contain", height:"100%"}} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div style={{ height:"300px", background:"#ce8bf8"}}>
            <img src="/3.png" style={{width:"100%", objectFit:"contain", height:"100%"}} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div style={{ height:"300px", background:"#fcc8d8"}}>
            <img src="/4.png" style={{width:"100%", objectFit:"contain", height:"100%"}} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div style={{ height:"300px", background:"#4b4b4b"}}>
            <img src="/5.png" style={{width:"100%", objectFit:"contain", height:"100%"}} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          <div style={{ height:"300px", background:"linear-gradient(to right top ,#41a1a0 ,#9ceac5)"}}>
            <img src="/6.png" style={{width:"100%", objectFit:"contain", height:"100%"}} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
        </Carousel>
      </div>
      <div  style={{textAlign:"center", marginBottom:"30px"}} className='second-font'>
          <Promotion  >

              - Mint Your NFTs +
          </Promotion>
      </div>
      <Page5 />
      <Page3 />
      <div style={{background:"#000"}}>
        <Right>
          <H className='first_font'>Sad clown coffee days:</H>
          <T className='first_font'>
            Mental health care and awareness is really important, but very big topic to tackle, so where do you start? You start small: a coffee day.
          </T>
          {/* Clown popularity has dwindled for the last century, as more and more people have come to see them as creepy or weird. This has caused a wave of sad clowns, who are unwanted by those they wish to entertain.</T> */}
          <T className='first_font'>
            We will be paying for everyone's drinks at a local independent café here in York and covid-dependant, we will have designated areas where people can sit and talk to others. The aim is that this small gesture will help to put a smile on people's faces and bring joy at a difficult time. If people then want to pay it forward, even better! Spreading a little bit of joy, one by one. We will also have a donation station, in case anyone would like to donate to Samaritans.



            {/* Desperate, they have decided to band together to become the Sad Clown Collective; this Circus is coming to the blockchain and they are gathering up NFT's... and in numbers. Will the Collective's collection put a smile on your face? */}
          </T>
          <T className='first_font'>
            We are hoping to continue hosting Sad Clown Coffee Days, spreading around the country and even internationally, to keep spreading joy to people's lives.
          </T>
          {/* <T>
            Inspired by Dan's Great-Uncle Ken Moroney's 'Clowns', Sad Clown Collective will be a collection of 8,888 hand drawn NFT's. This project will be an investors/collectors club, buying and selling NFT's and splitting the profits made between its members and various charities. There will also be a staking mechanism and token to be introduced. We will be using this collection to raise awareness for mental health and giving part of the minting profits to a charity here in the UK and staging real world meet ups to spread the awareness.
          </T> */}
          {/* Meta Four Studios are a digital art creative focused on making the best and most ingenious NFT Projects around. </T> */}
          {/* <T>Shortages of food and resources have unleashed war between cities. Pledging allegiance to the corrupt forces is the only way to obtain the key to survival: the digital avatars of destruction.</T>
<T>Get your Digital Avatar of Destruction and defeat your enemies to survive in a world with no place for the weak. Experience the most exciting vehicle combat game on the blockchain and play to earn amazing Crypto rewards.</T> */}
          {/* <Image>
<img src={element} alt="" style={{ width: "100%", height: "100%" }} />
</Image> */}
          {/* <Button
            type="button"
            whileHover={{ scale: 1.1, boxShadow: "0 0 25px #ffca38" }}
            whileTap={{ scale: 0.7 }}
        >Learn More</Button> */}
        </Right>

        {/* <img src='https://irp.cdn-website.com/a08a0621/dms3rep/multi/Clown+Gif.gif' style={{width:"100%"}} /> */}
      </div>
    </div>
  )
}

export default Sadclown
