import CARDA from './CARDA.png'
import Polycat1 from './Polycat1.png'
import Polycat2 from './Polycat2.png'
import Polycat3 from './Polycat3.png'
import LYGON from './LYGON.png'
import ETHE from './ETHE.png'
import ANCE from './ANCE.png'
import BITIC from './BITIC.png'
import Telum from './Telum.png'
import Machina from './Machina.png'
import Invictus from './Invictus.png'
import None from './None.png'
import C_Sloth from './C Sloth.png'
import Val from './Val.png'
import Cloudiee from './Cloudiee.png'
import Pete from './Pete.png'
import Andrez from './Andrez.png'
import The_Doctor from './The Doctor.png'
import Cisla from './Cisla.png'
import Mothercow from './Mothercow.png'
import Luna from './Luna.png'

export const data = [

    {bg:"linear-gradient(to right, #c6ffdd, #fbd786, #f7797d)",img:BITIC,name:"Q1",que:"Go Live!",desc:"Sad Clown Collective goes live! We will be kicking off our roadmap with a Sad Clown Coffee day, date to be announced. We have decided to host a free coffee day at a local independent coffee shop. We will be inviting people in for a chat and a coffee along with partnering up with a mental health charity to bring awareness to mental health, 10% of total mint will also go to this charity. We will be constantly marketing throughout Q1 to get as many of the right eyes and ears on the project.We will be expanding the team and hiring internally and externally from the project. We want to ensure the success of this project so we will be heavily investing and creating an infrastructure for the project to thrive.", details:"Bitic, the thunder dragon Polymon, holds domain over a large area of land. The land is mostly mountainous, and few other Polymon and humans live there. This is just as Bitic wants, as it prefers its own solitude. Bitic gathers enormous amounts of energy inside it simply by existing. Often, this energy needs to be released. Bitic releases it by creating large thunder and lightning explosions with it at the center. Those explosions do not harm Bitic, but make craters out of the nearby land. This is why Bitic prefers solitude, as anything near the explosion faces an early death.", rank:"01", isSale:true,main: false},
    {bg:"linear-gradient(to right, #ff9966, #ff5e62)",img:ETHE,name:"Q2", que:"What Comes Next?",desc:"Introducing staking, you can stake your Sad Clown NFT’s with us to earn monthly rewards. We will be sweeping up the floor prices in the project to ensure longevity and burning as many as we feel is necessary to steadily increase the demand for Sad Clowns.We will buy buying more NFT's for the vault, really expanding the Collective's collection.The Clown Council will be formed from the most active and influential members, they will be ambassadors for the project and hold forums and events within the space and members only discord. ", details:"Ethe, the legendary star steel dragon Polymon, is always roaming the world looking for powerful adversaries. Like Lygon, Ethe lives high up in the sky, beyond the atmosphere. Also like Lygon, it often comes descends. However, where Lygon comes down into the world to give gifts on specific days, Ethe only appears to affirm its supremacy. From high up beyond the atmosphere, Ethe is still able to see what is going on in the world. The moment it sees another Polymon boast of its strength, Ethe descends and challenges the other Polymon to a fight.",rbtn:"DARK",rbg:"#333",rank:"02", isSale:false,main: false},
    {bg:"linear-gradient(to right, #1e9600, #fff200, #ff0000)",img:CARDA,name:"Q3", que:"A Collection?",desc:"We will release our 2nd collective project, which is considerably smaller. This will be airdropped to those that hold more than 3 clowns first and the remainder will be minted afterwards. There will be a lot more mental health awareness coming in Q3: once we have reached a .1 eth floor price we will be giving 10% of the community wallet to charity.", details:"Carda is a legendary Polymon. It lives in the bottom of the deepest seas, and, as such, not many people have seen it. Carda acts as a protector of the deep seas, keeping watch both for human intrusions and also for other Polymon wishing to enter the area. If the need arises, Carda is more than capable of defending its territory and the Polymon it considers to be its wards.Carda’s more terrifying feature is its roar. Akin to a lion’s roar but magnified tenfold, it is often enough to cause other Polymon to flee without a fight.",rbtn:"WATER",rbg:"#0034af",rank:"03", isSale:false,main: false},
    {bg:"linear-gradient(to right, #0099f7, #f11712)",img:LYGON,name:"Q4", que:"Watch This Space.",desc:"We will be scheduling our first real world meet up for all holders who can attend! Entry will be gained by being a Sad Clown holder. Full details will be provided closer to the time.", details:"Lygon is the legendary celestial Polygon. Although it lives high up in the sky, beyond the atmosphere, its existence is well recorded.This is because Lygon often appears to others. In particular, it always appears on set dates during the year, at times when specific stars are visible together in the sky. Those times are well known to people. Lygon appears in a different location each time, but the locations where it might appear are always five specific areas. Great festivals are held there on the days where Lygon might appear. ",rbtn:"POISON",rbg:"#8b44c9",rank:"04", isSale:true,main: false},
    // {bg:"linear-gradient(to right, #ffe000, #799f0c)",img:Polycat3,name:"Catty",desc:"An uncommon Polymon in the Polyverse.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"05", isSale:false,main: false},
    // {bg:"linear-gradient(to right, #004ff9, #fff94c)",img:Polycat2,name:"Carp",desc:"A common Polymon in the Polyverse.", details:"",rbtn:"WATER",rbg:"#0034af",rank:"06", isSale:true,main: false},
    // {bg:"linear-gradient(to right, #add100, #7b920a)",img:Polycat1,name:"Purr",desc:"A common Polymon in the Polyverse.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"07", isSale:false,main: false},
    // {bg:"linear-gradient(to right, #03001e, #7303c0, #ec38bc, #fdeff9)",img:ANCE,name:"Ance",desc:"Ance created by BSC. A man bred PolyMon.", details:"Ance is the legendary lightning bird Polymon. It is also one of the few legendary Polymon – and Polymon in general – that does not have a nest of its own, or even a general area where it lives.Instead of having a permanent place, Ance simply follows storms. It as a supernatural instinct that allows it to know where in the world thunderstorms are currently ongoing, and exactly what direction they are heading. Ance is always traveling to the nearest thunderstorm. Once inside it, it flies among the clouds, absorbing the electrical energy of the storm and replenishing its powers.",rank:"08",main: false},

]

export const FactionData = [

    {bg:"linear-gradient(to right, #ffe000, #799f0c)",img:Telum,name:"Telum",desc:"The world's oldest and largest corporation has survived the apocalypse to develop DADs that take the best of traditional warfare vehicles to the next level.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"05", isSale:false,main: false},
    {bg:"linear-gradient(to right, #004ff9, #fff94c)",img:Machina,name:"Machina",desc:"MACHINA DADs are efficient killing machines. In a few years, the original rustic refurbished vehicles became fearsome war machines capable of destroying everything that stands in their way.", details:"",rbtn:"WATER",rbg:"#0034af",rank:"06", isSale:true,main: false},
    {bg:"linear-gradient(to right, #add100, #7b920a)",img:Invictus,name:"Invictus",desc:" The so-called “4 Horsemen of the Apocalypse” are the supreme leaders of this faction and revered as visionaries. Their DADs use a mysterious technology that gives them unique tactical advantages.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"07", isSale:false,main: false},

]

export const CardData = [

    {bg:"linear-gradient(to right, #ffe000, #799f0c)",img:None,name:"None",desc:"The world's oldest and largest corporation has survived the apocalypse to develop DADs that take the best of traditional warfare vehicles to the next level.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"05", isSale:false,main: false},
    {bg:"linear-gradient(to right, #004ff9, #fff94c)",img:C_Sloth,name:"C Sloth",desc:"MACHINA DADs are efficient killing machines. In a few years, the original rustic refurbished vehicles became fearsome war machines capable of destroying everything that stands in their way.", details:"",rbtn:"WATER",rbg:"#0034af",rank:"06", isSale:true,main: false},
    {bg:"linear-gradient(to right, #add100, #7b920a)",img:Val,name:"Val",desc:" The so-called “4 Horsemen of the Apocalypse” are the supreme leaders of this faction and revered as visionaries. Their DADs use a mysterious technology that gives them unique tactical advantages.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"07", isSale:false,main: false},
    {bg:"linear-gradient(to right, #ffe000, #799f0c)",img:Cloudiee,name:"Cloudiee",desc:"The world's oldest and largest corporation has survived the apocalypse to develop DADs that take the best of traditional warfare vehicles to the next level.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"05", isSale:false,main: false},
    {bg:"linear-gradient(to right, #004ff9, #fff94c)",img:Pete,name:"Pete",desc:"MACHINA DADs are efficient killing machines. In a few years, the original rustic refurbished vehicles became fearsome war machines capable of destroying everything that stands in their way.", details:"",rbtn:"WATER",rbg:"#0034af",rank:"06", isSale:true,main: false},
    {bg:"linear-gradient(to right, #add100, #7b920a)",img:Andrez,name:"Andrez",desc:" The so-called “4 Horsemen of the Apocalypse” are the supreme leaders of this faction and revered as visionaries. Their DADs use a mysterious technology that gives them unique tactical advantages.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"07", isSale:false,main: false},
    {bg:"linear-gradient(to right, #ffe000, #799f0c)",img:The_Doctor,name:"The Doctor",desc:"The world's oldest and largest corporation has survived the apocalypse to develop DADs that take the best of traditional warfare vehicles to the next level.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"05", isSale:false,main: false},
    {bg:"linear-gradient(to right, #004ff9, #fff94c)",img:Cisla,name:"Cisla",desc:"MACHINA DADs are efficient killing machines. In a few years, the original rustic refurbished vehicles became fearsome war machines capable of destroying everything that stands in their way.", details:"",rbtn:"WATER",rbg:"#0034af",rank:"06", isSale:true,main: false},
    {bg:"linear-gradient(to right, #add100, #7b920a)",img:Luna,name:"Luna",desc:" The so-called “4 Horsemen of the Apocalypse” are the supreme leaders of this faction and revered as visionaries. Their DADs use a mysterious technology that gives them unique tactical advantages.", details:"",rbtn:"GROUND",rbg:"#91705f",rank:"07", isSale:false,main: false},

]