import React from 'react'
import styled from 'styled-components'
import img from '../../components/Navbar/logo.png'
import Tilt from 'react-parallax-tilt';
import bg from '../Sadclown/bg1.jpg';
import blue from '../Sadclown/whaLe.png';
import theme from "styled-theming";
import tank from './tank.png'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { SiHomeassistant } from 'react-icons/si';
import { IoIosPodium } from 'react-icons/io';
import { SiAzuredataexplorer } from 'react-icons/si';
import { SiZig } from 'react-icons/si'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { BsBoxArrowUp } from 'react-icons/bs';
import "../Sadclown/index.css";
// import { T } from '../Page2/Page2'

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
});

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem  0;
    background-color:  rgba(51, 51, 51, 1);
    background-repeat: no-repeat;
    background-image: url(https://lirp.cdn-website.com/a08a0621/dms3rep/multi/opt/clown+5-1920w.png);
    background-size: 100% 100%;
    background-position: right bottom !important;
  
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 1rem 0 2rem 0;
        flex-direction: column;
        justify-content: space-around;
        background-size: 100% auto;
        background-color: #ffca38;
      }
`;

const Left = styled.div`
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
     
    @media only screen and (max-width: 768px) {
        margin-right: 0;
        width: 100%;
        padding: 0 1rem;
        height: auto;
      }
`;


const buttonColor = theme("theme", {
    light: "linear-gradient(to right, #000000, #434343)",
    dark: "linear-gradient(to right, #ff4e50, #f9d423)",
});

const bColor = theme("theme", {
    light: "#E5E5E5",
    dark: "#000000",
});

const iconColor = theme("theme", {
    light: "linear-gradient(to right, #0f0c29, #302b63, #24243e)",
    dark: "linear-gradient(to right, #fc00ff, #00dbde)",
});

const heroImg = theme("theme", {
    light: blue,
    dark: blue,
});

const HomePage = styled.section`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
height: 100vh;
width: 100%;
// background-image: url(${bg});
// background-size: 100% 100%;
// background-repeat: no-repeat;
 background-color: #000;

@media only screen and (max-width: 768px) {
    min-height: 50rem;
    width: 100%;
    padding: 0rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
}`;

const HeroImg = styled(motion.div)`
    width: 38rem;
    height: 30rem;
    background-size: 100% 100%;
background-repeat: no-repeat;
background-image: url(${heroImg});
    @media only screen and (max-width: 1400px) {
        width: 30rem;
        height: 22rem;
      }
    @media only screen and (max-width: 768px) {
        width: 15rem;
        height: 12rem;
      }
`;

const T = styled.p`
    color: ;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    color:#fff;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const Mobile = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        display:block;
        width: 100%;
        height: auto;
      }
`;

const Center = styled.div`
        width: auto;
        height: auto;
        display: grid;
        place-items: center;
`;

// const Button = styled.button`
//      background: ${buttonColor} ;
//      color: ${bColor};
//      border: none;
//      font-size: 5rem;
//      font-weight: bold;
//      cursor: pointer;
//      padding: 0.3rem 3rem;
//      border-radius: 3rem;
//      margin-top: 2rem;
//      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
//         -webkit-transform: perspective(1px) translateZ(0);
//         transform: perspective(1px) translateZ(0);
//         box-shadow: 0 0 1px rgba(0, 0, 0, 0);
//         -webkit-transition-duration: 0.3s;
//         transition-duration: 0.3s;
//         -webkit-transition-property: transform;
//         transition-property: transform;

//     &:hover, &:focus,&:active {
//         -webkit-transform: scale(1.1) rotate(4deg);
//         transform: scale(1.1) rotate(4deg);
//       }
//       @media only screen and (max-width: 1400px) {
//         font-size: 4rem;
//         padding: 0.3rem 2rem;
//       }

//      @media only screen and (max-width: 768px) {
//         font-size: 2rem;
//         padding: 0.3rem 1rem;
//       }
// `;

const ShowButton = styled(Link)`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background-color:#ffca38; // ${iconColor};
    color: #FFF ;
    font-weight: bold;
    cursor: pointer;
    position: fixed;
    height: 4rem;
    width: 3rem;
    border-radius: 0.5rem;
    transition:  0.5s linear;
    right: 3rem ;
    bottom: 10rem;
    display: grid;
    place-items: center;
    @media only screen and (max-width: 768px) {
      right: 2rem ;
    bottom: 5rem;
    }
    
`

const IconContainer = styled.div`
  display: flex;
  margin: 2rem 0 0 0;
`

const Icon = styled(motion.div)`
  width: 4.5rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 1rem;
  padding: 0.5rem;
  @media only screen and (max-width: 768px) {
    width: 3rem;
  height: 3rem;
  }
`
// background-image: url(${right});



export const H = styled.h1`
color: #000;
font-size: 2.5rem;
font-weight: bold;
margin-bottom: 2rem;
text-align: center;
@media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const Image = styled.div`
      width: 20rem;
      height: 2rem;
      margin: 0 0 1rem 50%;
  
      @media only screen and (max-width: 1700px) {
        margin: 0 0 1rem 40%;
      }

      @media only screen and (max-width: 1400px) {
        margin: 0 0 1rem 35%;
      }

      @media only screen and (max-width: 1100px) {
        margin: 0 0 1rem 30%;
    }
    @media only screen and (max-width: 998px) {
        margin: 0 0 1rem 50%;
        width: 16rem;
    }
    @media only screen and (max-width: 768px) {
        margin: 0 0 1rem 50%;
        width: 12rem;
    }
    @media only screen and (max-width: 600px) {
        margin: 0 0 1rem 50%;
        width: 8rem;
    }
`
const Right = styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align:center;
        height: 42rem;
       
        background-size: 100% 100%;
        background-repeat: no-repeat;

        padding: 3.5rem 4rem 0 6rem;

        @media only screen and (max-width: 1700px) {
            height: 43rem;
          }

          @media only screen and (max-width: 1500px) {
            height: 45rem;
          
          }
          @media only screen and (max-width: 1400px) {
            padding: 3.5rem 2rem 0 3rem;
          }

          @media only screen and (max-width: 1100px) {
            width: 100%;
            margin-top: 5rem;
        }
        @media only screen and (max-width: 998px) {
            width: 100%;
            height: 38rem;
        }

      @media only screen and (max-width: 768px) {
        width: 100%;
        min-height: 25rem  ; 
        padding: 15rem 1rem 7rem 1.5rem;
      }
`;

export const Button = styled(motion.button)`
  background-color:#ffca38;
  border: none;
  padding: 0 2rem;
  width:100px;
  color: #000;
  height: 3.5rem;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
`

// const T = styled.p`
//     color: #FFF;
//     font-weight: 500;
//     font-size: 1.3rem;
//     margin-bottom: 2rem;
//     text-align: left;
//     font-family: 'Nunito Sans', sans-serif;
//     @media only screen and (max-width: 768px) {
//         font-weight: 510;
//         font-size: 1rem;

//       }
// `;

// const H = styled.h1`
//     color: #ffca38;
//     font-size: 2.5rem;
//     font-weight: bold;
//     margin-bottom: 2rem;
//     text-align: left;
//     @media only screen and (max-width: 768px) {
//         font-weight: 510;
//         font-size: 1.2rem;
//         font-weight: bold;
//       }
// `;

const Page5 = () => {

    return (
        <Sec id="learn">
            <div style={{ width: "100%" }}>
                <H className='first_font' style={{ textAlign: "center" }}>

                   Minting Roadmap
                </H>


                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#ffca38', color: '#FFF' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="10%"
                        iconStyle={{ background: '#000', color: '#fff' }}
                        icon={<SiHomeassistant />}
                    >
                        <h4  style={{ fontSize: "1.4rem", color: "#000" }} className="vertical-timeline-element-subtitle first_font">We will host our first Sad Clown
                            Coffee Day! We will pay for
                            everyone's coffee for a full day,
                            a
                            offering the opportunity to sit
                            and talk. More details can be
                            found on our project details
                            page</h4>
                        <T>

                            {/* At D.A.D we fully believe that community trust is key. As such, rewarding our early supporters with quality Non-Fungible Tokens (NFTs) is our first task as a NFT project. While providing an extensive roadmap and a community raffle upon the completion of the early supporter minting phase. */}
                        </T>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#ffca38', color: '#FFF' }}
                        date="30%"
                        iconStyle={{ background: '#ffca38', color: '#fff' }}
                        icon={<IoIosPodium />}
                    >
                        <h3 className="vertical-timeline-element-title"></h3>
                        <h4  style={{ fontSize: "1.4rem", color: "#000" }} className="first_font vertical-timeline-element-subtitle"> We will release limited
                            edition merch for
                            holders only!</h4>
                        <T>

                            {/* High-quality artwork is another important aspect of D.A.D, alongside community trust. After all the NFTs have been minted, D.A.D’s arts and development team will reveal the Genesis D.A.D vehicles. Subsequently, a second community raffle will be held for all holders of Genesis D.A.D vehicles. */}

                        </T>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#ffca38', color: '#FFF' }}
                        date="50%"
                        iconStyle={{ background: '#ffca38', color: '#fff' }}
                        icon={<SiAzuredataexplorer />}
                    >
                        <h3 className="vertical-timeline-element-title"></h3>
                        <h4  style={{ fontSize: "1.4rem", color: "#000" }} className="first_font vertical-timeline-element-subtitle">We will release and
                            airdrop 5 of the rarest
                            Clowns in the collection
                            to random Sad Clown
                            holders</h4>
                        <T>
                            {/* The first major update for the D.A.D community the development team has been working on for months. All 7777 Genesis D.A.D NFT holders will be granted access to D.A.D’s Garage and all its utilities, which will be revealed in phase 3. One such utility for example is staking of the NFT to earn $PCORE, the currency required to manufacture the next generation of D.A.Ds. */}

                        </T>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#ffca38', color: '#FFF' }}
                        date="70%"
                        iconStyle={{ background: '#ffca38', color: '#fff' }}
                        icon={<SiZig />}
                    >
                        <h3 className="vertical-timeline-element-title"></h3>
                        <h4  style={{ fontSize: "1.4rem", color: "#000" }} className="first_font vertical-timeline-element-subtitle">The Sad Clown
                            community wallet will
                            open and we will make
                            our first NFT purchase
                            for the vault</h4>
                        <T>
                            {/* Introduction to the manufacturing process of D.A.D vehicles, how to create the next generation of D.A.D vehicles. And the revealing of Arenas, Land Ownership, and Drones. There will also be the first update to the Garage, improving the experience the community has as holders of Genesis D.A.D NFTs, the mini-game, and the competition reveal! */}

                        </T>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#ffca38', color: '#FFF' }}
                        date="90%"
                        iconStyle={{ background: '#ffca38', color: '#fff' }}
                        icon={<SiZig />}
                    >
                        <h3 className="vertical-timeline-element-title"></h3>
                        <h4  style={{ fontSize: "1.4rem", color: "#000" }} className="first_font vertical-timeline-element-subtitle">
                            We will sweep and burn to
                            ensure the project's value
                            continually increases
                        </h4>
                        <T>
                            {/* D.A.D Online sneak peeks and Alpha access whitelists. While our team holds the Alpha access lottery, we will start the $PCORE staking for governance rights. Revealing more aspects of the game as development progresses to its final stage before D.A.D Online goes public. */}
                        </T>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#ffca38', color: '#FFF' }}
                        date="100%"
                        iconStyle={{ background: '#000', color: '#fff' }}
                        icon={<SiZig />}
                    >
                        <h3 className="vertical-timeline-element-title"></h3>
                        <h4  style={{ fontSize: "1.4rem", color: "#000" }} className="first_font vertical-timeline-element-subtitle">100% Minted !!</h4>
                        {/* <T>
                            The start of D.A.D Online beta, marketplace, and second-generation Plasma Energy Core minting. In conjunction with the reveal of D.A.D Metaverse, comprehensive Serum usage guide and examples of Avatars that have already used the Serum.

                        </T> */}
                        <h4 className="first_font vertical-timeline-element-subtitle" style={{ color: "#000" }}>
                            We will begin work on our roadmap and keep working
                            hard to bring you the best project possible!

                        </h4>
                    </VerticalTimelineElement>
                </VerticalTimeline>
                {/* <div style={{margin:"auto", display:"block", padding:"2px", background:"#ffca38", width:"340px", borderRadius:"5px", textAlign:"center"}}>
                    <H style={{color:"#fff", textAlign:"center"}}>
                        100% Minted!!
                    </H>
                </div> */}
            </div>

           

        </Sec>

    )
}

export default Page5
