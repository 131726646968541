import React from 'react'
import styled from 'styled-components';
import theme from 'styled-theming';
import Tilt from 'react-parallax-tilt';
import { H } from '../../Page/Page1/Home';
import { T } from '../../Page/Page2/Page2';

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
});

export const bColor = theme("theme", {
    light: "linear-gradient(to right, #36d1dc, #5b86e5)",
    dark: "linear-gradient(to right, #0f2027, #203a43, #2c5364)",
});

const Card = (props) => {
    const Box = styled(Tilt)`
        height: auto; 
        width: 50%; 
        margin:auto !important;  
        // background-color: rgba(241, 92, 48, 1);
        transform: skewY(17deg);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 14px;
        margin: 2rem 0 0 0;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        @media only screen and (max-width: 768px) {
            height: auto; 
            width: 100%;
            
        }
    `;
    const Upper = styled.div`
        height: auto;
        width: 100%;
        `;

    return (
        <Box>
            <Upper>

                <H className='first_font'>
                    {props.name}
                </H>
                <T className='first_font'>
                    {props?.que}
                </T>
                <T className='first_font'>
                    {props.desc}
                </T>
                {/* <img src={`${props.img ? props.img : ""}`} alt="" style={{ width: "100%", height: "100%" }} /> */}
            </Upper>

        </Box>
    )
}

export default Card
